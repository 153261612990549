import { useEffect, useState } from "react"
import { projectFirestore } from "../firebase/config"


export const useDocument = (collection, documentId) => {
  const [document, setDocument] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const ref = projectFirestore.collection(collection).doc(documentId)

    const unsub = ref.onSnapshot((snaphot) => {
      
      const result = { ...snaphot.data(), id: snaphot.id }
      if (snaphot.data()) {
        setDocument(result)
        setError(null)
      } else {
        setError('Document id: '+ documentId + ' does not exist!')
      }

    }, (error) => {
      console.log(error)
      setError('Could not fetch document id: ' + documentId + ' from collection ' + collection)
    })

    // unsubscribe
    return () => unsub()

  }, [collection, documentId])

  return { document, error }

}
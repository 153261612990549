import { useHistory } from "react-router-dom";
import Avatar from "../../components/Avatar";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useFirestore } from "../../hooks/useFirestore";


export default function ProjectSummary({ project }) {
  const { user } = useAuthContext()
  const { deleteDocument } = useFirestore('projects')
  const history = useHistory()


  // We're not doing any async / await since if
  // the delete fails, the project will still be available
  // in the next screen
  const handleClick = (e) => {
    deleteDocument(project.id)
    history.push('/')
  }


  return (
    <div>
      <div className="project-summary">
        <h2 className="page-title">{project.name}</h2>
        <p className="created-by">
          By {project.createdBy.displayName}
        </p>
        <p className="due-date">
          Project due by {project.dueDate.toDate().toDateString()}
        </p>
        
        <p className="details">
          {project.details}
        </p>

        <h4>Project is assigned to</h4>
        <div className="assigned-users">
          {project.assignedUsersList.map(user => (
            <div key={user.photoURL}><Avatar src={user.photoURL} /></div>
          ))}
        </div>
      </div>
      {/* { project.createdBy.id === user.uid && ( */}
        <button className="btn" onClick={handleClick}>Mark this complete</button>
      {/* )} */}
    </div>
  )
}
import firebase from "firebase/app";
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyDuY_CR5Z7zmN0wkJ-GTuLikwA3mE2GVjk",
  authDomain: "thedojosite-5c0bb.firebaseapp.com",
  projectId: "thedojosite-5c0bb",
  storageBucket: "thedojosite-5c0bb.appspot.com",
  messagingSenderId: "938313193595",
  appId: "1:938313193595:web:3f04db4e4850174d932ade"
};

firebase.initializeApp(firebaseConfig)

const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, projectStorage, timestamp }
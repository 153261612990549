import { useEffect, useState } from "react"
import { projectAuth, projectFirestore } from "../firebase/config"
import { useAuthContext } from "./useAuthContext"

export const useLogin = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const login = async (email, password) => {
    setError(null)
    setIsPending(true)

    //sign the user out
    try {
      const res = await projectAuth.signInWithEmailAndPassword(email, password)

      if (!res) {
        throw new Error('Could not complete login with '+ email)
      }
      // dispatch logout
      dispatch({type: 'LOGIN', payload: res.user })

      await projectFirestore.collection('users').doc(res.user.uid)
        .update({ online: true})

      if(!isCancelled) {
        setIsPending(false)
        setError(null)
      }

    } catch(err) {
      if(!isCancelled) {
        console.log(err.message)
        setIsPending(false)
        setError(err.message)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { login, error, isPending }

}
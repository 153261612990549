import { useState } from "react"
import ProjectList from '../../components/ProjectList'
import { useCollection } from '../../hooks/useCollection'
import ProjectFilter from './ProjectFilter'
import { useAuthContext } from "../../hooks/useAuthContext"

// styles
import './Dashboard.css'



export default function Dashboard() {
  const { documents, isPending, error } = useCollection('projects')
  const [currentFilter, setCurrentFilter] = useState('all')
  const { user} = useAuthContext()
  
  const changeFilter = (newFilter) => {
    setCurrentFilter(newFilter)
  }

  const projects = documents ? documents.filter((document) => {
    switch (currentFilter) {

      case 'all':
        return true

      case 'mine':
        let assignedToMe = false
        document.assignedUsersList.forEach((u) => {
          if (u.id === user.uid) {
            assignedToMe = true
          }
        })
        return assignedToMe
      
      case 'development':
      case 'design': 
      case 'marketing': 
      case 'finance':
        // console.log(document.category, currentFilter)
        return document.category === currentFilter

      default:
        return true
    }
  }) : null

  return (
    <div className='dashboard'>
      <h2 className="page-title">Dashboard</h2>
      {error && <p className='error'>{error}</p>}
      {isPending && <p>Loading projects...</p>}
      {documents && <ProjectFilter currentFilter={currentFilter} changeFilter={changeFilter} />}
      {projects && <ProjectList projects={projects} />}

    </div>
  )
}

import { useEffect, useState } from 'react'
import  Select  from 'react-select'
import { useCollection } from '../../hooks/useCollection'
import { timestamp } from '../../firebase/config'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFirestore } from '../../hooks/useFirestore'
import { useHistory } from 'react-router-dom'

// styles
import './Create.css'



const categoryOptions = [
  { value: 'development', label: 'Development' },
  { value: 'design', label: 'Design' },
  { value: 'marketing', label: 'Marketing' },
  { value: 'finance', label: 'Finance' },
]

export default function Create() {
  const [name, setName] = useState('')
  const [details, setDetails] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [category, setCategory] = useState('')
  const [assignedUsers, setAssignedUsers] = useState([])

  const { documents, isPending } = useCollection('users')
  const [users, setUsers] = useState([])
  
  const [formError, setFormError] = useState(null)

  const { user } = useAuthContext()

  const { addDocument: addProject, response } = useFirestore('projects')

  const history = useHistory()

  useEffect(() => {
    if(documents) {
      setUsers(documents.map(user => {
        return { value: {...user, id: user.id}, label: user.displayName }
      }))
    }
  }, [documents])


  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError(null)

    if(!category.value) {
      setFormError('Please enter a value for category')
      return
    }

    if(assignedUsers.length < 1) {
      setFormError('Please assign the project to atlease a user')
      return
    }

    const createdBy = {
      displayName: user.displayName,
      photoURL: user.photoURL,
      id: user.uid
    }

    const assignedUsersList = assignedUsers.map((u) => {
      return {
        displayName: u.value.displayName,
        photoURL: u.value.photoURL,
        id: u.value.id
      }
    })

    const project = {
      name,
      details,
      dueDate:timestamp.fromDate(new Date(dueDate)),
      category: category.value,
      comments: [],
      createdBy,
      assignedUsersList

    }
    console.log(project)

    
      // add the project document to firestore
    await addProject(project)
    if(!response.error) {
      history.push('/')
    }
    
  }


  return (
    <div className='create-form'>
      <h2 className="page-title">Create a new project</h2>
      <form onSubmit={handleSubmit}>
        <label>
          <span>Project Name</span>
          <input 
            required
            type='text'
            onChange={(e) => {setName(e.target.value)}}
            value={name}
          />
        </label>
        <label>
          <span>Project Details</span>
          <textarea 
            required
            onChange={(e) => {setDetails(e.target.value)}}
            value={details}
          ></textarea>
        </label>
        <label>
          <span>Project Due Date</span>
          <input
            required 
            type="date" 
            onChange={(e) => setDueDate(e.target.value)} 
            value={dueDate}
          />
        </label>
        <label>
          <span>Select Category</span>
          <Select
            options={categoryOptions}
            onChange={(option) => setCategory(option)}
          />
        </label>
        <label>
          <span>Assigned to</span>
          {!isPending && <Select
            options={users}
            onChange={(option) => setAssignedUsers(option)}
            isMulti
          />}
          {isPending && <p>Loading users</p>}
        </label>
        <button className='btn'>Add Project</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  )
}
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

// components
import Dashboard from './pages/dashboard/Dashboard'
import Login from './pages/login/Login'
import Signup from './pages/signup/Signup'
import Create from './pages/create/Create'
import Project from './pages/project/Project'
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import OnlineUsers from './components/OnlineUsers'

// styles
import './App.css'


function App() {
  const { authIsReady, user } = useAuthContext()

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          {user && <Sidebar />}
          <div className="container">
            <Navbar />
            <Switch>
              <Route exact path="/">
                {!user && <Redirect to='/login' />}
                {user && <Dashboard />}
              </Route>          
              <Route path="/create">
                {user && <Create />}
                {!user && <Redirect to='/login' />}
              </Route>
              <Route path="/projects/:id">
                {user && <Project />}
                {!user && <Redirect to='/login' />}
              </Route>
              <Route path="/login">
                {!user && <Login />}
                {user && <Redirect to='/' />}
              </Route>
              <Route path="/signup">
                {!user && <Signup />}
                {user && <Redirect to='/' />}
              </Route>
            </Switch>
          </div>
          {user && <OnlineUsers /> }
        </BrowserRouter>
      )}
    </div>
  );
}

export default App
